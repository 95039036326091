import React, { useEffect, useState } from "react";
import { Container, Table, Button, Modal } from "react-bootstrap";
import { supabase } from "../supabaseClient";
import CustomSpinner from "./Spinner";
function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });
  const [selectedJob, setSelectedJob] = useState(null);
  const [showModalStatus, setShowStatus] = useState(false);
  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const { data, error } = await supabase.from("jobs").select("*");

      if (error) {
        setModalContent({ title: "Error", message: "Error fetching jobs." });
        setShowStatus(true);
        console.error("Error fetching jobs:", error);
      } else {
        setJobs(data);
      }
    } catch (error) {
      setModalContent({ title: "Error", message: "Error fetching jobs." });
      setShowStatus(true);
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (jobId, action) => {
    try {
      const { error } = await supabase
        .from("jobs")
        .update({ status: action })
        .eq("id", jobId);

      if (error) {
        setModalContent({
          title: "Error",
          message: `Error ${action} Business.`,
        });
        setShowStatus(true);
      } else {
        setModalContent({
          title: "Success",
          message: `Business ${action} successfully.`,
        });
        setShowStatus(true);
        fetchJobs();
      }
    } catch (error) {
      setModalContent({ title: "Error", message: `Error System fail` });
      setShowStatus(true);
    }
  };

  const handleViewDetails = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedJob(null);
  };

  return (
    <Container className="mt-4">
      <h1>Pending Jobs</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Status</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <CustomSpinner />
          ) : (
            jobs.map((job, index) => (
              <tr key={job.id}>
                <td>{index + 1}</td>
                <td>{job.name}</td>
                <td>{job.status}</td>
                <td>
                  <Button onClick={() => handleViewDetails(job)}>
                    View Details
                  </Button>
                </td>
                <td>
                  {job.status !== "pending" ? (
                    <Button
                      variant="danger"
                      onClick={() => handleAction(job.id, "pending")}
                      className="ml-2"
                    >
                      Revoke
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="success"
                        onClick={() => handleAction(job.id, "accepted")}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleAction(job.id, "rejected")}
                        className="ml-2"
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Modal show={showModalStatus} onHide={() => setShowStatus(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStatus(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedJob
              ? `${selectedJob.cat_name}(${selectedJob.sub_name})`
              : "Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedJob && (
            <div>
              <p>
                <strong>Name:</strong> {selectedJob.name}
              </p>
              <p>
                <strong>Nationality:</strong> {selectedJob.nationality}
              </p>
              <p>
                <strong>Age:</strong> {selectedJob.age}
              </p>
              <p>
                <strong>Gender:</strong> {selectedJob.gender}
              </p>
              <p>
                <strong>Education:</strong> {selectedJob.education}
              </p>
              <p>
                <strong>Working Experience:</strong>{" "}
                {selectedJob.working_experience}
              </p>
              <p>
                <strong>Years of Experience:</strong>{" "}
                {selectedJob.years_of_experience}
              </p>
              <p>
                <strong>Language Skill:</strong> {selectedJob.language_skill}
              </p>
              <p>
                <strong>Skill:</strong> {selectedJob.cat_name}{" "}
                {selectedJob.sub_name}
              </p>
              <p>
                <strong>Elderly:</strong>{" "}
                {selectedJob.is_elderly ? "Yes" : "No"}
              </p>
              {selectedJob.profile_image_url && (
                <div>
                <p>
                  <strong>Profile Image:</strong>
                
                </p>
                  <img
                  src={selectedJob.profile_image_url}
                  alt="Profile"
                  style={{ width: "100px", height: "100px" }}
                />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Jobs;
