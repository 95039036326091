// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import JobRegistrations from "./components/JobRegistrations";
import BackOffice from "./components/BackOffice";
import ManageUsers from "./components/ManageUsers";
import NotFound from "./components/NotFound";
import Footer from "./components/Footer";
import NavBar from "./components/Navbar";

function App() {
  return (
    <BrowserRouter>
      <div className="d-flex flex-column min-vh-100">
        <div className="flex-grow-1">
          <NavBar/>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<BackOffice />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/job-registrations" element={<JobRegistrations />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
