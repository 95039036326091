// src/components/BackOffice.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const BackOffice = () => {
  return (
    <Container>
      <h1>Dashboard</h1>
      <ul>
        <li><Link to="/manage-users">Manage Users</Link></li>
        <li><Link to="/job-registrations">Job Registrations</Link></li>
      </ul>
    </Container>
  );
};

export default BackOffice;
