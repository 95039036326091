// App.js
import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Table, Button, Container } from "react-bootstrap";
import Spinner from "./Spinner";

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const { data, error } = await supabase.from("users").select("*");

    if (error) console.error(error);
    else setUsers(data);
    setLoading(false);
  };

  const toggleBan = async (userId, isBanned) => {
    const { error } = await supabase
      .from("users")
      .update({ banned: !isBanned })
      .eq("id", userId);

    if (error) console.error(error);
    else {
      if (!isBanned) {
        await supabase.auth.signOut(userId);
      }
      fetchUsers();
    }
  };

  return (
    <Container className="mt-4">
      <h1>Manage User</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Banned</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Spinner />
          ) : (
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.banned ? "Yes" : "No"}</td>
                <td>
                  <Button onClick={() => toggleBan(user.id, user.banned)}>
                    {user.banned ? "Unban" : "Ban"}
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
}

export default ManageUsers;
